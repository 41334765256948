//@ngInject
function TrackingService($window, $log, activationConfig, nsStorage) {
  var lastTrackedAdobeTitle;

  function track(data) {
    if ($window.dataLayer) {
      $window.dataLayer.push(data);
    }
  }
  function adobeTrack(data) {
    if ($window.adobeDataLayer) {
      $window.adobeDataLayer.push(data);
    }
  }

  // Don't override dataLayer variables set by the ACP
  if (activationConfig.isStandalone()) {
    // Set global properties
    track({
      nsApp: activationConfig.get('app'),
      nsVersion: activationConfig.get('version'),
      nsBrand: activationConfig.get('brand'),
      nsPlatform: activationConfig.get('platform'),
      nsMode: activationConfig.getMode()
    });
  }

  function sendEventDelegate(event, category, action, label, value) {
    track({
      event: event,
      nsEvent_category: category,
      nsEvent_action: action,
      nsEvent_label: label,
      nsEvent_value: value || 0
    });
  }

  function sendEvent(category, action, label, value) {
    // Sending no value will break event tracking...default to 0
    // to make GTM happy.

    sendEventDelegate('nsEvent', category, action, label, value);
  }

  function sendCustomEvent(event, category, action, label, value) {
    sendEventDelegate(event, category, action, label, value);
  }

  return {
    sendOnboardingEvent: function (category, action, label) {
      track({
        event: 'nsOnboardingEvent',
        nsOnboardingEvent_category: category,
        nsOnboardingEvent_action: action,
        nsOnboardingEvent_label: label
      });
    },

    sendPageView: function (title, path) {
      $log.debug(
        'Reporting pageview for page -> "' +
          title +
          '" and path -> "' +
          path +
          '"'
      );

      track({
        event: 'nsPageView',
        nsPageView_page: 'Activation/4/' + path,
        nsPageView_title: title
      });

      // Dont send the same page view event twice in a row
      if (lastTrackedAdobeTitle !== title) {
        const { hostname } = $window.location;
        const accountId = nsStorage.local('account_id');
        adobeTrack({
          event: 'aaPageView',
          pageData: {
            event: 'pageView',
            pageName: `${hostname}|Activation|${title}`,
            siteSection: `${hostname}|Activation`,
            url: $window.location.href
          },
          siteData: {
            hostName: hostname
          },
          userData: {
            accountID: accountId || 'anonymous'
          }
        });
        lastTrackedAdobeTitle = title;
      }
    },

    sendCardSuccessfullyActivated: function (message) {
      $log.debug('Reporting card successfully activated');

      track({
        event: 'nsCardActivated',
        message: message
      });
    },

    sendEvent: sendEvent,
    sendCustomEvent: sendCustomEvent
  };
}

export default TrackingService;
